import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Title from 'antd/es/typography/Title';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import './vendor-dashboard-page.scss';
import { MetricsSectionViewContainer } from '../../components/metrics-section-view-container/v2/metrics-section-view-container';
import { MetricsTabViewContainer } from '../../components/metrics-tab-view-container/v2/metrics-tab-view-container';
import { SectionView } from '../../components/section-view/v2/section-view';
import { columns, extractIds } from '../../components/tab-view/v2/card-def';
import { TabView } from '../../components/tab-view/v2/tab-view';
import { MomentDate } from '../../models/date.model';
import { getIsInvalid, getToken, setSessionToken } from '../../services/auth/auth-slice';
import { Filter, getMonthFilterOptions } from '../../shared/helper';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { ActionData, useSalesforceTrigger } from '../../shared/useAnalytics';

const DATE_FORMAT = 'YYYY-MM-DD';

export const VendorDashboardPage = () => {
  const monthFilterOptions: Filter = getMonthFilterOptions();

  const [monthFilter, setMonthFilter] = useState<moment.Moment>(moment().subtract(1, 'months'));
  const vdToken = useSelector(getToken);
  const isInvalidSession = useAppSelector(getIsInvalid);
  const [searchParams] = useSearchParams();
  const accId: string = searchParams.get('id') || '';
  const sfToken: string = searchParams.get('sessionId') || '';
  const view: string = searchParams.get('view') || 'tab';
  const dispatch = useAppDispatch();
  const kpiIds: string[] = extractIds(columns);

  const { triggerHover, triggerClick } = useSalesforceTrigger('VendorDashboard');
  const [dateRange, setDateRange] = useState<{
    to: string;
    from: string;
  }>({
    to: monthFilterOptions[Object.keys(monthFilterOptions)[0]].end.format(DATE_FORMAT),
    from: monthFilterOptions[Object.keys(monthFilterOptions)[0]].start.format(DATE_FORMAT),
  });

  useEffect(() => {
    dispatch(setSessionToken(sfToken));
  });

  const fetchKPIs = async ({
    from,
    to,
    gridId,
    kpiIdList,
  }: {
    from: string;
    to: string;
    gridId: string;
    kpiIdList: string[];
  }) => {
    const { data } = await axios.get(
      `/vendor-dashboard-service/kpis?from=${from}&to=${to}&sf_grid_id=${gridId}&kpi_ids=${kpiIdList.join(
        ',',
      )}`,
      {
        headers: {
          'x-vendorDashboard-token': vdToken,
          'X-API-Version': '2', // for new hybrid kpis version
        },
      },
    );
    return data;
  };

  const {
    data: kpiData,
    isLoading,
    error: kpiDataError,
  } = useQuery(
    ['fetchKPIs', { monthFilter, accId }],
    () => fetchKPIs({ from: dateRange.from, to: dateRange.to, gridId: accId, kpiIdList: kpiIds }),
    {
      enabled: !!vdToken && !isInvalidSession,
    },
  );

  const onMonthChange = (from: MomentDate) => {
    const actionData: ActionData = {
      duration: {
        value: from?.toString() || '',
        unit: 'Month',
      },
      actionName: 'filter',
    };
    triggerClick(actionData);
    const dateRangeSelection = {
      to: from?.endOf('month').format(DATE_FORMAT) || '',
      from: from?.date(1).format(DATE_FORMAT) || '',
    };
    setMonthFilter(from || moment().subtract(1, 'months'));
    setDateRange(dateRangeSelection);
  };

  if (isInvalidSession) {
    return (
      <div className="warning-message-container">
        <Title level={5}>Invalid token</Title>
      </div>
    );
  }

  if (accId === '') {
    return (
      <div className="warning-message-container">
        <Title level={5}>Account ID is not provided.</Title>
      </div>
    );
  }
  return (
    <div
      onMouseEnter={(e) => {
        triggerHover(e.type);
      }}
      onMouseLeave={(e) => {
        triggerHover(e.type);
      }}
      className="vendor-dashboard-organism"
    >
      {view === 'tab' ? (
        <MetricsTabViewContainer kpisLoading={isLoading} onMonthChange={onMonthChange}>
          <TabView kpiData={kpiData?.data} dataError={!!kpiDataError} />
        </MetricsTabViewContainer>
      ) : (
        <MetricsSectionViewContainer kpisLoading={isLoading} onMonthChange={onMonthChange}>
          <SectionView kpiData={kpiData?.data} dataError={!!kpiDataError} />
        </MetricsSectionViewContainer>
      )}
    </div>
  );
};
